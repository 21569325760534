<template>
  <div>
    <div class="app-header">
      <div class="container">
        <div class="app-title">Burriram.app - App เพื่อคนบุรีรัมย์</div>
      </div>
    </div>    
    <div class="app-container">
      <div class="container">
        <div class="app-content">
          <vue-markdown-it :source='source' :html='true' />
        </div>
      </div>
    </div>    
    <div class="app-footer">
      <div class="container">

      </div>
    </div>    
  </div>
</template>

<script>
import VueMarkdownIt from 'vue3-markdown-it';
import README from './README.md';
export default {
components: {
    VueMarkdownIt
  },
  data() {
    return {
      source: README.trim()
    }
  }
}
</script>

<style>
.app-header {
  height: 56px;
  width: 100%;
  background-color: #BD9BCF;
}
.app-title {
  color: #fff;
  position: relative;
  top: 7px;
  font-size: 24px;
}
.app-content {
  padding: 1.5rem 0;
}
.app-table-district tr td:first-child { 
  width: 60px;
}
.app-footer {
  background-color: #f9f9f9;
  min-height: 300px;
}
.app-content h1 {
  color: #BD9BCF;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.app-content a {
  color: #BD9BCF;
}
.table-responsive {
  margin: 1rem 0;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
}
.table-responsive table:not(.hljs-ln) {
  margin: 0 !important;
  min-width: max-content;
  margin: 1rem 0;
  width: 100%;
}
.table-responsive table:not(.hljs-ln) thead {
  background-color: #f9f2fd;
}
.table-responsive table:not(.hljs-ln) thead tr {
  border-bottom: solid 2px #c7c7c7;
}
.table-responsive table:not(.hljs-ln) thead th {
  padding-left: 5px;
  padding-right: 5px;
}
.table-responsive table:not(.hljs-ln) td, 
.table-responsive table:not(.hljs-ln) th {
  padding: 0.2rem 0.3rem;
}
.table-responsive table:not(.hljs-ln) tbody tr {
  border-bottom: solid 1px #e4e4e4;
}
</style>
